import React from "react"
import { Link } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import Disqus from "../components/disqus"
// import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = ({data}) => {
  console.log(data)
return (
  <Layout>
    <SEO title="Home" />
    <div style={{ textAlign: `center`}}><p>Welcome to Soundpump.net</p></div>
    
    <div style={{ maxWidth: `100px`, marginBottom: `1.45rem`, margin: `3rem auto` }}>
      <Img fluid={data.logo.childImageSharp.fluid} />
    </div>
    <Disqus />
    
    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)}

export default IndexPage

export const pageQuery = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;