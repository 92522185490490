import React from "react"
import ReactDisqusComments from "react-disqus-comments";
// import { useStaticQuery, graphql } from "gatsby"


const Disqus = () => (
  <div style={{width: `400px`}}>
    <ReactDisqusComments
    shortname="soundpump"
    identifier="soundpump_main"
    url="https://soundpump.net" />
  </div>
)

export default Disqus

